import React, { FC } from 'react';
import { PageBlock } from '@design-system/page-block';
import loadable from '@loadable/component';
import { pageBlockMapper } from 'mappers/pageBlock';

import { DangerouslySetInnerHtml } from 'layout';
import DebugComponent from 'components/BodyRender/DebugComponent';

import { QuestionAnswerListProps } from './models';

const Accordion = loadable(() => import('@design-system/accordion'), {
  resolveComponent: (components) => components.Accordion,
});

const ExampleRawList = ({ list }: QuestionAnswerListProps) => (
  <>
    {list.map(({ question, answer }) => (
      <>
        <h3>{question}</h3>
        <div>
          <DangerouslySetInnerHtml html={answer} />
        </div>
      </>
    ))}
  </>
);

const questionAnswerAccordionMapper = ({
  list,
  variant: [{ settings }],
}: QuestionAnswerListProps) => ({
  ...settings,
  items: list.map(({ question, answer, disabled }) => ({
    title: question,
    children: <DangerouslySetInnerHtml html={answer} />,
    disabled,
  })),
});

const typesMapper = {
  default: [
    (item) => (process.env.NODE_ENV === 'development' ? <DebugComponent {...item} /> : null),
    (item) => item,
  ],
  accordion: [Accordion, questionAnswerAccordionMapper],
  exampleList: [ExampleRawList],
};

const QuestionAnswerList: FC<QuestionAnswerListProps> = (props) => {
  const {
    variant: [{ variant }],
    list,
    page_block,
  } = props;
  const pageBlock = pageBlockMapper(page_block);

  const [ItemComponent, itemMapper] = typesMapper[variant] || typesMapper.default;

  return list?.length > 0 ? (
    <PageBlock {...pageBlock} className="question-answer-list-block">
      <ItemComponent {...(itemMapper ? itemMapper(props) : props)} />
    </PageBlock>
  ) : null;
};

export default QuestionAnswerList;
